/* eslint-disable camelcase */

import parse from 'html-react-parser'
import React from 'react'
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row
} from 'react-bootstrap'
import arrowUpBoldSvg from '../../images/arrow-up-bold.svg'
import { useDeleteQuestion, useGetCompanyInfoById, useSubmitNewQuestion } from '../../utils/api'
import { useGetListingQuery } from '../api'
import Spinner from '../spinner'
import Announcements from './communicationComponents/Announcements'
import NumberedRoundBage from './communicationComponents/NumberedRoundBage'
import QuestionAnswerCard from './communicationComponents/QuestionAnswerCard'

/*
 *
 * Page
 *
 */

const Page = (props) => {
  // props
  const { listingId } = props
  console.log(props)

  // state
  const [showAskQuestionModal, setShowAskQuestionModal] = React.useState(false)
  const [newQuestion, setNewQuestion] = React.useState('')
  const [questionToDelete, setQuestionToDelete] = React.useState('')
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)

  // query
  const listingQuery = useGetListingQuery({ listingId, isVendor: false }) // N.B. from this page, we are always the buyer!
  const { data: { questions = [] } = {} } = listingQuery
  const questionsUnanswered = questions.filter(q => q.answer.trim() === '')
  const questionsAnsweredPrivate = questions.filter(q => q.answer.trim() !== '' && q.isPrivate)
  const questionsAnsweredPublic = questions.filter(q => q.answer.trim() !== '' && !q.isPrivate)

  const getCompony = useGetCompanyInfoById()
  const submitNewQuestionMutation = useSubmitNewQuestion()
  const deleteQuestionMutation = useDeleteQuestion()
  // method
  const deleteQuestion = (id) => {
    setQuestionToDelete(id)
    setShowDeleteModal(true)
  }
  const confirmDelete = () => {
    deleteQuestionMutation.mutate({
      listingId,
      questionID: questionToDelete
    })
  }
  const handleKeyDown = (e) => {
    e.target.style.height = 'inherit'
    e.target.style.height = `${e.target.scrollHeight + 8}px`
  }

  const submitNewQuestion = () => {
    submitNewQuestionMutation.mutate({
      listingId,
      newQuestion: { question: newQuestion }
    })
    setShowAskQuestionModal(false)
    setNewQuestion('')
  }
  return (
    <>
      <div className="card-body rounded-soft bg-white px-1 px-md-5 d-flex flex-column flex-grow-1 my-4">
        <div className="align-items-center mb-4  ">
          <span className=" inter-font-content-title">Listing Notes</span>
        </div>
        {listingQuery.isSuccess && (
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Notes about the listing.</Form.Label>
                <div className="py-3 border p-3">
                  {listingQuery.isSuccess &&
                    listingQuery.data &&
                    typeof listingQuery.data.buyer_notes === 'string' &&
                    parse(listingQuery.data.buyer_notes)}
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}
        <div className="mt-4 mb-3  d-flex align-items-center justify-content-between">
          <span className=" inter-font-content-title">Questions</span>
          <Button
            className=""
            variant="outline-primary"
            onClick={() => {
              setShowAskQuestionModal(true)
            }}
          >
            Ask a Question
          </Button>
        </div>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Questions that you have asked.</Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
              <Card className="shadow-none border">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <img
                    src={arrowUpBoldSvg}
                    alt=""
                    width={18}
                    className="mr-2"
                  />
                  <NumberedRoundBage
                    questionLength={questionsUnanswered.length}
                  />
                  <b> My Unanswered Questions</b>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="border-top">
                {deleteQuestionMutation.isLoading || listingQuery.isFetching
                  ? <div className="d-flex justify-content-center p-5"><Spinner /></div>

                  : questionsUnanswered.length === 0
                    ? (
                    <Card.Body>No unanswered questions
                    {submitNewQuestionMutation.isLoading &&
                      <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                      </Card.Body>
                      )
                    : <>
                        {questionsUnanswered.map((data, index) => {
                          return (

                            <QuestionAnswerCard
                              key={index}
                              data={data}
                              deleteQuestion={deleteQuestion}
                              />
                          )
                        })}
                        {submitNewQuestionMutation.isLoading &&
                          <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                        </>
                }
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="0">
              <Card className="shadow-none border">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <img
                    src={arrowUpBoldSvg}
                    alt=""
                    width={18}
                    className="mr-2"
                  />
                  <NumberedRoundBage questionLength={questionsAnsweredPrivate.length} />
                  <b> My Questions</b>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="border-top">
                  {questionsAnsweredPrivate.length === 0
                    ? (
                    <Card.Body>No private questions</Card.Body>
                      )
                    : <>
                          {questionsAnsweredPrivate.map((data, index) => {
                            return (
                              <QuestionAnswerCard
                                key={index}
                                data={data}
                                deleteQuestion={deleteQuestion}
                                />
                            )
                          })}
                          </>
                      }
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <Accordion defaultActiveKey="0">
              <Card className="shadow-none border">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <img
                    src={arrowUpBoldSvg}
                    alt=""
                    width={18}
                    className="mr-2"
                  />
                  <NumberedRoundBage questionLength={questionsAnsweredPublic.length} />
                  <b> Public Questions</b>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="border-top">
                  {questionsAnsweredPublic.length === 0
                    ? (
                    <Card.Body>No public questions</Card.Body>
                      )
                    : <>
                            {questionsAnsweredPublic.map((data, index) => {
                              return (
                                <QuestionAnswerCard
                                  key={index}
                                  data={data}
                                  deleteQuestion={deleteQuestion}
                                  />
                              )
                            })}
                            </>}
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Announcements isVendor={false} listingId={listingId} />
          </Col>
        </Row>

        {/* ANCHOR Modals */}
        <Modal
          show={showAskQuestionModal}
          onHide={() => { setShowAskQuestionModal(false); setNewQuestion('') }}
        >
          <Modal.Body>
            <button
              type="button"
              className="close"
              onClick={() => { setShowAskQuestionModal(false); setNewQuestion('') }}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <h5 className="m-0 ">Ask a Question</h5>

            <div className="py-0">
              <Form.Group>
                <Form.Label>Submit a question about the listing.</Form.Label>

                <Row className="my-4">
                  <Col sm={12}>
                    <div className="d-flex gap-3">
                      <h3 className="px-3 py-1">Q</h3>
                      <div className="flex-grow-1">
                        <b>{getCompony.isSuccess && (getCompony.data.legalName)}</b>
                        <Form.Control
                          type="text"
                          as="textarea"
                          style={{ height: 100 + 'px', overflow: 'hidden' }}
                          onKeyDown={handleKeyDown}
                          onFocus={handleKeyDown}
                          value={newQuestion}
                          className="mt-2"
                          onChange={(e) => {
                            setNewQuestion(e.target.value)
                          }}
                          placeholder="Write your question here."
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button
                type="button"
                onClick={() => { setShowAskQuestionModal(false); setNewQuestion('') }}
                variant="outline-primary"
              >
                Close
              </Button>
              <Button variant="primary" onClick={submitNewQuestion}>
                Submit Question
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body>
            <h5 className="m-0 mb-3">Delete question?</h5>
            <div className="py-3">
              This cannot be undone.
            </div>
            <div className="d-flex justify-content-between mt-5">
                <Button type="button" onClick={() => setShowDeleteModal(false)} variant="outline-primary">Close</Button>
                <Button type="button" onClick={() => { confirmDelete(); setShowDeleteModal(false) }} variant="primary">Delete</Button>
            </div>
        </Modal.Body>
    </Modal>

        {process.env.NODE_ENV !== 'production' && (
          <pre>listingQuery ={JSON.stringify(listingQuery.data, null, 2)}</pre>
        )}
      </div>
    </>
  )
}

export default Page
